@import '/src/styles/styles.scss';
.container {
    display: flex;
    width: 100%;
  
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f2f4f6;
  }
  
  .innerBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    min-height: 90vh;
    align-items: center;
  
    background-color: white;
    padding: 30px;
    border-radius: 30px;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
  
    justify-content: flex-start;
    align-items: center;
    max-width: 600px;
  }
  
  .message {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 30px 0;
  }

  .logo {
    width: 400px;
    height: 150px;
    object-fit: contain;
    margin: 0 0 40px 0;

  }

  .link {
    @include link;
  }
  
  @media screen and (max-width: 620px) {
    .innerBox {
      margin: 0 20px;
    }
  }