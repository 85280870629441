@import '/src/styles/styles.scss';

.header {
  @include fullwidth-cont;
  padding: 10px 0;
  align-items: center;
  border-bottom: 1px solid $text-color-primary;
  margin: 0 0 20px 0;
  min-height: 70px;
}
.wrapper {
  @include wrapper;
  align-items: center;
}

.menu_list {
  list-style: none;
  @include container;
  margin: 0;
  padding: 0;
}

.logo_img {
  width: 200px;
  height: 80px;
  object-fit: contain;
  margin: 0 60px 0 0;
  padding: 0;
}

.menu_item {
  margin: 0 15px 0 0;
  &:last-of-type {
    margin: 0;
  }
}

.menu_link {
  border-radius: 10px;
  background-color: $bg-first;
  text-decoration: none;
  color: $green-color;
  padding: 12px 15px;
}

.menu_special {
  background-color: blueviolet;
  color: white;
}

.logoutBtn {
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid blueviolet;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: $container-width) {
  .header {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 890px) {
  
  .wrapper {
    flex-direction: column;
    min-height: 160px;
  }
  .logo_img {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 620px) {
  .menu_item {
    min-width: 120px;
  }
  .menu_list {
    min-height: 120px;
    flex-wrap: wrap;
  }
  .wrapper {
    padding: 30px;
  }
}

@media screen and (max-width: 430px) {
  .menu_list {
    min-height: 140px;
  }
}
