
.container {
    display: flex;
    width: 100%;
  }
  
  .innerBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    min-height: 90vh;
    align-items: center;
  }

  .loader {
    width: 40px;
    aspect-ratio: 1;
    animation: 
      l3-1 2s infinite linear,
      l3-2 3s infinite steps(1) -.5s;
  }
  @keyframes l3-1 {
    0%   {transform: perspective(150px) rotateX(  0deg) rotateY(0deg)}
    50%  {transform: perspective(150px) rotateX(180deg) rotateY(0deg)}
    100% {transform: perspective(150px) rotateX(180deg) rotateY(180deg)}
  }
  @keyframes l3-2 {
    0%  {background: purple}
    33% {background: blueviolet}
    66% {background: blue}
  }
