@import '/src/styles/styles.scss';
.visibleBlock {  
  animation: toggle 0.4s ease-in forwards;
}

.hiddenBlock {  
  opacity: 0;
  visibility: hidden;
  // animation: togglerev 0.001s ease-in forwards;
// animation: toggle 3s infinite;
}

.visibleT {  
  animation: toggle 0.4s ease-in alternate forwards;
}

.hiddenT {
  animation: toggle 0.25s ease-in alternate forwards;
// animation: toggle 3s infinite;
}


@keyframes toggle {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes togglerev {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
   
    opacity: 0;
    visibility: hidden;
  }
}


.openSec {  
  margin: 0 0 30px 0;
  @include container;
  align-items: center;
  flex-direction: column;
  min-height: 30vh;    
  opacity: 0;
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.wrapper {
  @include container;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.floatingBtn {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: transparent;
  border: 1px solid rgb(49, 47, 47);
  font-size: 16px;
  padding: 4px;
  width: 28px;
  border-radius: 10px;
  cursor: pointer;
}


.cardContent {
  margin: 0;
  @include container;
  align-items: center;
  flex-direction: column;

}

.char_header {
  @include container;
  max-width: 600px;
  // justify-content: space-between;
  flex-direction: column;
  margin: 0 0 10px 0;
  padding: 0;
}

.catbtns {
  @include container;
  justify-content: flex-start;
}

.par {
  color: rgb(49, 47, 47);
  font-size: 13px;
}

.pinyin {
  font-size: 18px;
  line-height: 24px;
}

.translate {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  min-height: 70px;
}

.charblock {
  @include container;
  align-items: center;
  flex-direction: column;
  max-width: 550px;
  position: relative;
}

.starred {
  background-color: grey!important;
}

.empty {
  background-color: white;
}

.favbtn {
  position:absolute;
  top: 40px;
  right: 40px;
  border: 1px solid blueviolet;
  border-radius: 5px;
  padding: 6px 12px;
  background-color: transparent;
  transition: background-color .3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: rgb(234, 234, 234);
    transition: background-color .3s ease-in;
  }
  &_infav {
    background-color: blueviolet;
    transition: background-color .3s ease-in;
  }
}

.buttons {
  @include container;
  margin: 30px 0 0 0;
  max-width: 500px;
  justify-content: flex-start;
  align-items: flex-start;
}

.headerbtn {
  background-color: blue;
  border: 1px solid rgb(49, 47, 47);
  font-size: 13px;
  padding: 6px 12px;
  max-width: 150px;
  border-radius: 10px;
  margin: 0 15px 0 0;
  transition: background-color .8s ease-in;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: grey;
    transition: background-color .8s ease-in;
  }
}

.btn {
  max-width: 150px;
  padding: 10px 15px;
  border-radius: 10px;
  border: 0;
  cursor: pointer;

}

.btnprev {
  border: 2px solid rgb(221, 241, 189);
  background-color: white;
  color: black;
}

.btnnext {  
  background-color: rgb(221, 241, 189);
  margin: 0 0 30px 0;
}

.togglebtn {
  background-color: aquamarine;
}


.btntext {
  visibility: hidden;
  color: black;
  position: absolute;
  right: -30px;
  top: 20px;
  font-size: 13px;
  font-weight: 600;
}

.starbtn {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: transparent;
  background-image: url('../../images/star-transp.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 10px;
  transition: background-color 0.4s ease-in;
  &_starred {
    background-color: white;
    transition: background-color 0.4s ease-in;
  }
  &:hover .btntext {
    visibility: visible;
  }
}

.anotherStar {
  position: absolute;
  right: 10px;
  top: 10px;
}

.starbtntwo {
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: transparent;
  background-image: url('../../images/star-transp.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.4s ease-in;
  &:disabled {
  }
  &_starred {
    background-color: $darker-main-first;
    transition: background-color 0.4s ease-in;
  }
  &:hover .btntext {
    visibility: visible;
  }
 
}

.charac {
  margin: 15px 0 40px 0;
  font-size: 40px;
  line-height: 48px;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 690px) {
  .char_header {
    justify-content: flex-end;
    margin: 0 0 10px 0;
  }

  .headerbtn {
    margin: 0 15px 0 0;
  }
}
