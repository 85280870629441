.container {
    display: flex;
    width: 100%;
  }
  
  .innerBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 90vh;
    align-items: center;
  }
  
  .form {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 450px;
    margin: 40px 0 0 0;
  }
  
  .input {
    margin: 0 0 20px 0;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid blueviolet;
  }
  
  .input:focus {
    border: 2px solid black;
    background-color: rgba(0, 0, 0, 0.09);
    color: blueviolet;
  }
  
  .input::placeholder {
  }
  
  .button {
    color: white;
    background-color: blueviolet;
    border-radius: 15px;
    padding: 15px 18px;
    border: 0;
    max-width: 180px;
    cursor: pointer;
  }
  
  .langs {
    display: flex;
    padding: 0;
    margin: 20px 0;
  }
  
  .radio {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    &__label {
      margin: 0 20px 0 0;
      border: 3px solid black;
      padding: 10px;
      border-radius: 20px;
      
    cursor: pointer;
    }
    &:checked + .radio__label {
      
      background-color: black;
    }
  }
  