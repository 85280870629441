* {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
/* sizes: */
$tab-size-padding: 25px;
$mobile-size-padding: 15px;
$container-width: 1180px;
$border-radius-default: 20px;
$border-radius-small: 5px;
$page-margin-top: 30px;
$card-padding: 30px;

/*colors: */
$text-color-primary: #1d3444;
$bg-first: #dceaf5;
$green-color: rgb(0, 61, 51);
$main-color-accent: #f58a07;
$accent-faded: #f3a749c8;
$darker-main-first: #9dc1db;

@mixin container {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

@mixin fullwidth-cont {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

@mixin wrapper {
  width: 100%;
  max-width: $container-width;
  display: flex;
  box-sizing: border-box;
}

@mixin link {
  // text-transform: uppercase;
  font-family: 'Roboto', Verdana, Geneva, Tahoma, sans-serif;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  color: blue;
  transition: color .5s ease;
  font-weight: 500;
  line-height: 24px;
  padding: 0;
  margin: 0;
  &:hover {
    color: darkgrey;
    transition: color .5s ease;
  }
}