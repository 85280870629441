@import '/src/styles/styles.scss';

.container {
   @include fullwidth-cont;
    min-height: 90vh;
    /* background-color: #f2f4f6;*/
    background-color: white;
  }
  
  .innerBox {
    @include wrapper;
    min-height: 90vh;
    flex-direction: column;  
    justify-content: flex-start;    
    align-items: flex-start;
  }
  
  .message {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 24px;
    line-height: 30px;
  }

.btns {
  @include wrapper;

}

.btn {
  margin: 0 20px 0 0;
  border-radius: 10px;
  padding: 12px 16px;
  border: 0;
  background: blueviolet;
  color: white;
  cursor: pointer;

  &:last-of-type {
    margin: 0;
  }
}

.starred {
  background-color: transparent;
  border: 2px solid blueviolet;
  color: blueviolet;
}



.postsection {
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}
  
@media screen and (max-width: $container-width) {

.container {
  padding: 0 30px;
}

}