@import "/src/styles/styles.scss";

.task {
  @include container;
  border: 1px solid rgb(0, 61, 51);
  border-radius: 10px;
  padding: 15px 20px;
  flex-direction: column;
  margin: 0 20px 20px 0;
  max-width: 550px;
  position: relative;
}

.taskOpn {
  border: 0;
  cursor: pointer;
  border-radius: 10px;
  padding: 16px 14px;
  max-width: 150px;
  background-color: rgb(179, 224, 111);
  color: black
}

.showPinyin {
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  padding: 12px 14px;
  background-color: aquamarine;
  font-size: 14px;
  height: 40px;
  width: 150px;
}

.taskpar {
  font-weight: 500;
  font-size: 16px;
}

.chinese {
  font-size: 26px;
  line-height: 30px;
}

.replies {
  visibility: hidden;
  opacity: 0;
  transition: visibility opacity 0.4s ease-in;
  position: relative;
}

.starred {
  background-color: rgb(222, 93, 216)!important;
}

.empty {
  background-color: white;
}

.soundbutton {
  position: absolute;
  right: 0;
  top: 10px;
  width: 30px;
  height: 30px;
  background-image: url('../../images/sound.svg');
  background-size: cover;
  border: 0;
  cursor: pointer;
}

.lessonPinyin {
  visibility: hidden;
  opacity: 0;
  transition: visibility opacity 0.4s ease-in;
}

.isVisible {
  visibility: visible;
  opacity: 1;
}

.favbtn {
  position:absolute;
  top: 60px;
  right: 20px;
  border: 1px solid blueviolet;
  border-radius: 5px;
  padding: 6px 12px;
  background-color: transparent;
  transition: background-color .3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: rgb(234, 234, 234);
    transition: background-color .3s ease-in;
  }
  &_infav {
    background-color: blueviolet;
    transition: background-color .3s ease-in;
  }
}

@media screen and (max-width: 1180px) {
.task {
  max-width: 450px;
}
}



@media screen and (max-width: 980px) {
.task {
  max-width: 350px;
}
}

@media screen and (max-width: 790px) {
  .task {
    margin: 0 0 20px 0;
    max-width: 550px;
  }
}
