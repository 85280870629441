@import '/src/styles/styles.scss';

$formcolor1: rgb(10, 83, 46);

.form {
    @include wrapper;
    flex-direction: column;  
    justify-content: flex-start;    
    align-items: flex-start;
  }
  
  .input {
    margin: 0 0 20px 0;
    width: 100%;
    max-width: 600px;
    height: 30px;
    border: 2px solid $formcolor1;
    border-radius: 5px;
    padding: 5px;
  }
  
  .button {
    border: 0;
    border-radius: 10px;
    padding: 14px 18px;
    background-color: $formcolor1;
    color: white;
    cursor: pointer;
  }