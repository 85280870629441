@import '/src/styles/styles.scss';

.container {
    display: flex;
    width: 100%;
  }
  
  .innerBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 90vh;
    align-items: center;
  }
  
  .form {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 450px;
    margin: 40px 0 0 0;
  }
  .form__element{
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .form__iconbtn {
    display: flex;
    position: absolute;
    right: 12px;
    top: 8px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
  .icon {
    width: 25px;
    height: 25px;
  }

  .input {
    margin: 0 0 20px 0;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid blue;
  }
  
  .parMessage {
    color: red;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    margin: 0 0 30px 0;
    box-sizing: border-box;
    &_success {
      color:green;
      font-weight: 600;
    }
  }
  
  .input:focus {
    border: 2px solid black;
    background-color: rgba(0, 0, 0, 0.09);
    color: blue;
  }
  
  .input::placeholder {
  }
  
  .button {
    color: white;
    background-color: blue;
    border-radius: 15px;
    padding: 15px 18px;
    border: 0;
    max-width: 180px;
    cursor: pointer;
    &:disabled {
      background-color: rgb(214, 214, 214);
      cursor: default;
    }
  }
  