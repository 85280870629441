@import '/src/styles/styles.scss';

.container {
   @include fullwidth-cont;
    min-height: 90vh;
    /* background-color: #f2f4f6;*/
    background-color: white;
  }
  
  .innerBox {
    @include wrapper;
    min-height: 90vh;
    flex-direction: column;  
    justify-content: flex-start;    
    align-items: flex-start;
  }
  


.postsection {
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0 50px;
}



.infopar {
  font-size: 14px;
  color: greenyellow;
}

.second {
  margin: 20px 0 60px;
  border-top: 2px solid rgb(75, 149, 107);
}

.secondbtn {
  background-color: rgb(75, 149, 107);

}

@media screen and (max-width: 1080px) {
  .innerBox {
    padding: 0 30px;
  }
  
}